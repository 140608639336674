// Функция для генерации уникального ID для новых точек
export function generateUniqueId() {
    return Date.now() + Math.floor(Math.random() * 1000);
}

export function dmsToDecimal(dms) {
    const parts = dms.match(/(\d+)[°\s](\d+)'(\d+(\.\d+)?)"(N|S|E|W)/);
    if (!parts) return null;

    let degrees = parseFloat(parts[1]);
    const minutes = parseFloat(parts[2]);
    const seconds = parseFloat(parts[3]);
    const direction = parts[5];

    let decimal = degrees + (minutes / 60) + (seconds / 3600);


    // Учитываем направление
    if (direction === 'S' || direction === 'W') {
        decimal *= -1;
    }
    return decimal; // Возвращаем число
}
