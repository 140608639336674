import {iconList, placesData, setCurrentFeature} from './../index';
import {MARKER_TYPE_ZOOM_DEFAULT} from "./data";
import {popup, addPopupToMap, showEditForm, updatePopup} from "./interactive";
import mapboxgl from "mapbox-gl";

export const placeLayerId = 'places';

function getIconById(markerTypeId) {
    return iconList.find(icon => icon.id === parseInt(markerTypeId));
}

function getFontName() {
    const fontName = localStorage.getItem('selectedFontName');
    if (fontName) {
        return [fontName];
    }
    return ["HelveticaNeueCyr Heavy"];
}

export function addPlacesLayer(map, placesData) {
    if (map.getSource('places')) {
        map.getSource('places').setData(placesData);
        return;
    }

    // Добавляем источник данных без кластеризации
    map.addSource('places', {
        type: 'geojson',
        data: placesData,
    });

    map.addLayer({
        id: 'unclustered-point',
        type: 'symbol',
        source: 'places',
        filter: ['all',
            ['==', ['get', 'gif'], false], // Include only non-GIF markers
            ['has', 'marker_type_image']    // Ensure marker_type_image exists
        ],
        layout: {
            'icon-image': ['get', 'marker_type'], // Убедитесь, что marker_type соответствует вашим иконкам
            'icon-size': 1,
        },
        minzoom: 10, // Например, показывать метки только при зуме 10 и выше
    });

    map.addLayer({
        'id': placeLayerId,
        'type': 'symbol',
        'source': 'places',
        'filter': ['all',
            ['==', ['get', 'gif'], false], // Include only non-GIF markers
            ['has', 'marker_type_image']    // Ensure marker_type_image exists
        ],
        'layout': {
            'icon-image': ['get', 'marker_type_image'],
            'icon-allow-overlap': true,
            // ... other layout properties ...
        },
        minzoom: 10, // Например, показывать метки только при зуме 10 и выше

    });


    // Обработчики для отдельных меток
    map.on('click', 'unclustered-point', function (e) {
        const coordinates = e.features[0].geometry.coordinates.slice();
        const properties = e.features[0].properties;

        const popup = new mapboxgl.Popup({
            closeButton: false,
            closeOnClick: false
        });

        addPopupToMap(map, popup, coordinates, properties.id, properties.name, null);
    });

    map.on('mouseenter', 'unclustered-point', function () {
        map.getCanvas().style.cursor = 'pointer';
    });
    map.on('mouseleave', 'unclustered-point', function () {
        map.getCanvas().style.cursor = '';
    });

    _addPlacesLayer(map);
}

// Функция для добавления пользовательских маркеров (например, изображений)
export function _addPlacesLayer(map) {
    // Удаляем существующий слой, если он есть
    if (map.getLayer(placeLayerId)) {
        map.removeLayer(placeLayerId);
    }

    // Добавляем слой для маркеров
    map.addLayer({
        'id': placeLayerId,
        'type': 'symbol',
        'source': 'places',
        'filter': ['==', ['get', 'gif'], false],
        'layout': {
            'icon-image': ['get', 'marker_type'],
            'icon-allow-overlap': true,
            'icon-size': [
                'interpolate',
                ['linear'],
                ['zoom'],
                0, ['*', ['coalesce', ['get', 'zoom'], MARKER_TYPE_ZOOM_DEFAULT], 0.35], // Увеличенный размер на низком зуме
                10, ['*', ['coalesce', ['get', 'zoom'], MARKER_TYPE_ZOOM_DEFAULT], 0.55],
                15, ['*', ['coalesce', ['get', 'zoom'], MARKER_TYPE_ZOOM_DEFAULT], 0.75],
                20, ['*', ['coalesce', ['get', 'zoom'], MARKER_TYPE_ZOOM_DEFAULT], 0.95] // Еще больше на максимальном зуме
            ]
        },
        minzoom: 10, // Например, показывать метки только при зуме 10 и выше

    });

    // Удаляем существующий слой с именами точек, если он есть
    if (map.getLayer('places-name-layer')) {
        map.removeLayer('places-name-layer');
    }

    if (!map.getLayer('places-name-shadow-layer')) {
        map.addLayer({
            'id': 'places-name-shadow-layer',
            'type': 'symbol',
            'source': placeLayerId,
            'layout': {
                'text-field': ['get', 'name'],
                // "text-font": ["BankGothic RUSS Medium"],
                "text-font": getFontName(),
                // "text-font": ["Fallout Regular Regular"], # для Fallout
                'text-size': 14,
                'text-offset': [
                    0,
                    ['*', ['get', 'zoom'], 0.55]
                ],
                'text-anchor': 'top'
            },
            'paint': {
                'text-color': 'rgba(0, 0, 0, 0.5)', // Тень: полупрозрачный чёрный
                'text-translate': [3, 3], // Сдвиг тени
                'text-halo-color': 'rgba(0, 0, 0, 0)', // Без обводки для тени
                'text-halo-width': 0
            }
        });
    }

    // Добавляем слой для отображения имен точек
    if (!map.getLayer('places-name-layer')) {
        map.addLayer({
            'id': 'places-name-layer',
            'type': 'symbol',
            'source': placeLayerId,
            'layout': {
                'text-field': ['get', 'name'],
                // "text-font": ["BankGothic RUSS Medium"],
                "text-font": getFontName(),
                // "text-font": ["Fallout Regular Regular"], # для Fallout
                'text-size': 14,
                'text-offset': [
                    0,
                    ['*', ['get', 'zoom'], 0.55]
                ],
                'text-anchor': 'top'
            },
            'paint': {
                'text-color': ['get', 'text_color'], // Основной цвет текста
                'text-halo-color': '#000000', // Обводка: белый
                'text-halo-width': 1.5, // Толщина обводки
                'text-halo-blur': 0.7, // Размытие обводки
                'text-translate': [0, 5] // Сдвиг текста
            }
        });
    }
}

export function addGifMarkers(map, placesData) {
    if (window.gifMarkers) {
        window.gifMarkers.forEach(marker => marker.remove());
        window.gifMarkers = [];
    } else {
        window.gifMarkers = [];
    }

    placesData.features.forEach(function(feature) {
        if (feature.properties.gif) {
            const coordinates = feature.geometry.coordinates;
            const properties = feature.properties;
            const markerType = properties.marker_type;

            const markerTypeData = iconList.find(icon => icon.name === markerType);

            if (markerTypeData && markerTypeData.gif) {
                const element = document.createElement('div');
                element.className = 'gif-marker';

                const img = document.createElement('img');
                img.src = markerTypeData.gif; // Use the GIF URL
                element.appendChild(img);

                // Add event listeners to the marker element
                element.addEventListener('click', function(e) {
                    e.stopPropagation();
                    setCurrentFeature(feature);
                    showEditForm(feature);
                });

                element.addEventListener('mouseenter', function(e) {
                    e.stopPropagation();
                    map.getCanvas().style.cursor = 'pointer';

                    // Update the popup content and location
                    addPopupToMap(map, popup, coordinates, properties.id, properties.name, properties.description, null);
                });

                element.addEventListener('mouseleave', function(e) {
                    e.stopPropagation();
                    map.getCanvas().style.cursor = '';

                    // Remove the popup
                    popup.remove();
                });

                const marker = new mapboxgl.Marker(element)
                    .setLngLat(coordinates)
                    .addTo(map);

                window.gifMarkers.push(marker);
            }
        }
    });
}


export function processFeatures(features) {
    features.forEach(function(feature) {
        const markerType = feature.properties.marker_type;
        const markerTypeData = iconList.find(icon => icon.name === markerType);

        if (markerTypeData && markerTypeData.gif) {
            feature.properties.gif = true;
            feature.properties.marker_type_image = null;
        } else {
            feature.properties.gif = false;
            feature.properties.marker_type_image = markerType;
        }
    });
}


// Функция для обновления слоя при изменении данных
export function updatePlacesLayerData(map, newData) {
    const source = map.getSource(placeLayerId);
    processFeatures(newData.features);
    if (source) {
        source.setData(newData);
        addGifMarkers(map, newData);
    } else {
        console.error(`Источник данных для слоя ${placeLayerId} не найден`);
        addPlacesLayer(map, newData);
        addGifMarkers(map, newData);
    }
}


// Функция для управления видимостью слоя
export function togglePlacesLayerVisibility(map, isVisible) {
    const visibility = isVisible ? 'visible' : 'none';
    map.setLayoutProperty(placeLayerId, 'visibility', visibility);
}
