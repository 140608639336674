import {API_URL} from './config';


const CATEGORIES_URL_SUFFIX = '/api/categories/'
const SUBCATEGORIES_URL_SUFFIX = '/api/subcategories/'
const CATEGORIES_URL = `${API_URL}${CATEGORIES_URL_SUFFIX}`
const SUBCATEGORIES_URL = `${API_URL}${SUBCATEGORIES_URL_SUFFIX}`


// Функция для загрузки категорий
export async function populateCategories() {
    try {
        const response = await apiRequest(CATEGORIES_URL);
        return await response.json(); // Возвращаем список категорий с подкатегориями
    } catch (error) {
        console.error('Ошибка загрузки категорий и подкатегорий:', error);
        return [];
    }
}


export async function populateSubCategories() {
    try {
        const response = await apiRequest(SUBCATEGORIES_URL);
        return await response.json(); // Возвращаем список подкатегорий
    } catch (error) {
        console.error('Ошибка загрузки подкатегорий:', error);
        return [];
    }
}


export function populateFormCategories(categoriesData, selectedCategory, selectedSubcategory) {
    const categorySelect = document.getElementById('category-select');
    const subcategorySelect = document.getElementById('subcategory-select');

    // Populate categories
    categoriesData.forEach(category => {
        const option = document.createElement('option');
        option.value = category.id;
        option.textContent = category.name;
        categorySelect.appendChild(option);
    });

    // Set the selected category if it exists
    if (selectedCategory) {
        categorySelect.value = selectedCategory.id; // Устанавливаем значение категории
    }

    // Handle category selection change
    categorySelect.addEventListener('change', () => {
        const selectedCategoryId = parseInt(categorySelect.value);
        const selectedCategory = categoriesData.find(cat => cat.id === selectedCategoryId);

        // Clear existing subcategory options
        subcategorySelect.innerHTML = '';

        // Populate subcategories for the selected category
        if (selectedCategory && selectedCategory.subcategories) {
            selectedCategory.subcategories.forEach(subcategory => {
                const option = document.createElement('option');
                option.value = subcategory.id;
                option.textContent = subcategory.name;
                subcategorySelect.appendChild(option);
            });
        }

        // Устанавливаем подкатегорию по умолчанию, если она была выбрана ранее
        if (selectedSubcategory) {
            subcategorySelect.value = selectedSubcategory.id; // Устанавливаем значение подкатегории
        }
    });

    // Populate subcategories for the initially selected category
    const initialCategory = categoriesData.find(cat => cat.id === parseInt(categorySelect.value));
    if (initialCategory && initialCategory.subcategories) {
        initialCategory.subcategories.forEach(subcategory => {
            const option = document.createElement('option');
            option.value = subcategory.id;
            option.textContent = subcategory.name;
            subcategorySelect.appendChild(option);
        });
    }

    // Set the selected subcategory if it exists
    if (selectedSubcategory) {
        subcategorySelect.value = selectedSubcategory.id; // Устанавливаем значение подкатегории
    }
}



