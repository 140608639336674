import {API_URL} from "./config";


/**
 * Получение изображений для точки
 * @param {number} featureId - ID точки
 * @returns {Promise<Array>} - Массив изображений
 */
export const getPointImages = async (featureId) => {

    const response = await apiRequest(`${API_URL}/api/points/${featureId}/images/`, {
        method: 'GET',
    });

    if (!response.ok) {
        throw new Error(`Не удалось получить изображения: ${response.statusText}`);
    }

    return await response.json();
};

/**
 * Загрузка изображений для точки
 * @param {number} featureId - ID точки
 * @param {Array} images - Массив объектов с файлом и ID группы
 * @returns {Promise<Array>} - Массив загруженных изображений
 */
export const uploadPointImages = async (featureId, images) => {
    const formData = new FormData();
    images.forEach((image, index) => {
        formData.append(`images[${index}]`, image.file);
        formData.append(`groups[${index}]`, image.groupId);
    });

    const response = await apiRequest(`${API_URL}/api/points/${featureId}/images/`, {
        method: 'POST',
        body: formData,
    });

    if (!response.ok) {
        throw new Error(`Не удалось загрузить изображения на сервак: ${response.statusText}`);
    }

    return await response.json();
};

/**
 * Удаление изображений для точки
 * @param {Array} imageIds - Массив ID изображений для удаления
 * @param {number} featureId - ID точки
 * @returns {Promise<void>}
 */
// TODO поменять параметры местами
export async function deletePointImages(imageIds, featureId) {

    const deleteUrl = `${API_URL}/api/points/${featureId}/images/`;

    try {
        const response = await apiRequest(deleteUrl, {
            method: 'DELETE',
            body: JSON.stringify({
                images_to_delete: imageIds  // Передаем массив ID изображений для удаления
            }),
        });

        if (!response.ok) {
            throw new Error(`Не удалось удалить изображения: ${response.statusText}`);
        }

        console.log(`Изображения с ID ${imageIds.join(', ')} успешно удалены.`);
    } catch (error) {
        console.error(`Ошибка при удалении изображений:`, error);
        alert(`Произошла ошибка при удалении изображений.`);
    }
}
