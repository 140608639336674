import mapboxgl from 'mapbox-gl';

// Функция для добавления стандартных контролов навигации
export function addNavigationControl(map) {
    const navControl = new mapboxgl.NavigationControl();
    // TODO can pass param position: ['top-left', 'top-right', 'bottom-left', 'bottom-right', ...]
    map.addControl(navControl);
}

// Функция для добавления кнопки измерения масштаба
export function addScaleControl(map) {
    const scaleControl = new mapboxgl.ScaleControl({
        maxWidth: 80,
        unit: 'metric'
    });
    map.addControl(scaleControl, 'bottom-right');
}

// Пример пользовательского контрола
export function addFullscreenControl(map) {
    const customControl = new mapboxgl.FullscreenControl();
    map.addControl(customControl, 'top-right');
}
