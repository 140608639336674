export const API_URL = 'https://api.tap-map.net';
export const API_ADMIN_TOKEN = '9075473d885ef7b772fe1e1601b8ac797968a0f5';

// local Eug
// export const API_URL = 'http://127.0.0.1:8000';
// export const API_ADMIN_TOKEN = 'd7d322a120efcb126665b17fe5ca99ed4340c043';

// local Jojo
// export const API_URL = 'http://127.0.0.1:8000';
// export const API_ADMIN_TOKEN = 'd53bef81fbc750964394f50044a11b3ae6bbc456';


// чтоб чекать а то заебался в это втыкаться
export function isLocalBackend() {
    try {
        const url = new URL(API_URL);
        // Проверяем, является ли хост 'localhost' или '127.0.0.1'
        const isLocalhost = url.hostname === 'localhost' || url.hostname === '127.0.0.1';
        // Проверяем, используется ли нестандартный порт (например, 3000)
        const isLocalPort = url.port && (url.port === '3000' || url.port === '8000');
        // Проверяем, используется ли протокол 'http' или 'https'
        const isHttpProtocol = url.protocol === 'http:' || url.protocol === 'https:';
        // Если хост локальный и протокол HTTP(S), считаем, что это локальный бэкэнд
        return isLocalhost && isHttpProtocol && isLocalPort;
    } catch (e) {
        // Если URL недопустим, возвращаем false
        return false;
    }
}
