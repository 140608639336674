import {getMarkerTypes} from './api/markerTypes';
import {API_URL, isLocalBackend} from "./api/config";


// Функция для загрузки и добавления изображений маркера
// TODO mb del?
function loadAndAddImage(url, imageName, map, callback) {
    map.loadImage(url, (error, image) => {
        if (error) {
            console.error(`Ошибка загрузки изображения маркера ${imageName}:`, error);
            throw error;
        }
        map.addImage(imageName, image);
        if (callback) callback();  // Вызываем коллбек, если он есть
    });
}

export async function loadAllMapImages(map) {

    try {
        const markerTypes = await getMarkerTypes();

        // Initialize iconList
        const iconList = markerTypes.map(markerType => ({
            id: markerType.id,
            name: markerType.name,
            url: markerType.logo, // URL to the static image
            gif: markerType.gif // URL to the GIF image
        }));

        if (!isLocalBackend()) {

            iconList.forEach(markerType => {
                if (markerType.url && markerType.url.startsWith('http://')) {
                    markerType.url = markerType.url.replace('http://', 'https://');
                }
                if (markerType.gif && markerType.gif.startsWith('http://')) {
                    markerType.gif = markerType.gif.replace('http://', 'https://');
                }
            });
        }


        // Load images for non-GIF markers
        const loadImagesPromises = iconList.map(markerType => {
            if (markerType.url && !markerType.gif) { // Only non-GIF images
                const imageName = markerType.name;

                return new Promise((resolve, reject) => {
                    map.loadImage(markerType.url, (error, image) => {
                        if (error) {
                            console.error(`Error loading image ${imageName}:`, error);
                            resolve(); // Skip this image and continue
                        } else {
                            map.addImage(imageName, image);
                            resolve();
                        }
                    });
                });
            }
            return Promise.resolve();
        });

        await Promise.all(loadImagesPromises);

        return iconList;
    } catch (error) {
        console.error('Ошибка при загрузке всех изображений маркера:', error);
        return [];
    }
}

