import React, {useEffect, useState} from 'react';
import {Button, CloseButton, Col, Form, Modal, Row} from 'react-bootstrap';
import {deletePointImages, getPointImages, uploadPointImages,} from '../modules/api/images';
import {createPhotoGroup, deletePhotoGroup, fetchPhotoGroups,} from '../modules/api/photoGroups';
import '../styles/dragAndDrop.css';


function ImageGroupsModal({feature, onClose}) {

    const [show, setShow] = useState(true);
    const [groups, setGroups] = useState([]);
    const [imagesToDelete, setImagesToDelete] = useState([]);
    const [groupsToDelete, setGroupsToDelete] = useState([]);
    // Внутри компонента добавьте состояние для доступных групп
    const [availableGroups, setAvailableGroups] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        fetchGroupsAndImages();
    }, []);

    const fetchGroupsAndImages = async () => {
        try {

            // Параллельное выполнение запросов
            const [allGroups, featureImages] = await Promise.all([
                fetchPhotoGroups(),
                // Получаем изображения для точки
                getPointImages(feature.properties.id)
            ]);

            // Устанавливаем доступные группы в состояние
            setAvailableGroups(allGroups);

            // Извлекаем уникальные группы

            // const uniqueGroups = extractUniqueGroups(featureImages);
            // // Добавляем изображения в группы
            // uniqueGroups.forEach((group) => {
            //     group.images = featureImages.filter(
            //         (img) => img.group && img.group.id === group.id
            //     );
            // });
            // setGroups(uniqueGroups);

            const groupMap = {};
            featureImages.forEach((image) => {
                if (image.group) {
                    if (!groupMap[image.group.id]) {
                        groupMap[image.group.id] = {
                            id: image.group.id,
                            tempId: image.group.id.toString(),
                            name: image.group.name,
                            images: []
                        };
                    }
                    groupMap[image.group.id].images.push(image);
                }
            });

            const uniqueGroups = Object.values(groupMap);
            setGroups(uniqueGroups);

        } catch (error) {
            console.error('Ошибка при получении изображений и групп:', error);
        } finally {
            setLoading(false);
        }
    };

    // TODO переписал, мб удалить потом
    const extractUniqueGroups = (images) => {
        const groupMap = {};
        images.forEach((image) => {
            if (image.group) {
                groupMap[image.group.id] = {
                    id: image.group.id,
                    tempId: image.group.id.toString(),
                    name: image.group.name,
                    images: [],
                };
            }
        });
        return Object.values(groupMap);
    };

    const generateTempId = () => {
        return 'temp-' + Math.random().toString(36).substr(2, 9);
    };

    const handleAddGroup = () => {

        const unusedGroups = getUnusedGroups();
        if (unusedGroups.length === 0) {
            alert('Все доступные группы уже используются');
            return;
        }

        const newGroup = {
            id: null,
            tempId: generateTempId(),
            name: '',
            images: [],
            isDragging: false,
        };
        setGroups([...groups, newGroup]);
    };

    const handleDeleteGroup = (group) => {

        const imageIdsToDelete = group.images.map((img) => img.id).filter((id) => id != null);
        setImagesToDelete([...imagesToDelete, ...imageIdsToDelete]);

        setGroups(groups.filter((g) => g.tempId !== group.tempId));
    };

    const handleAddImagesToGroup = (e, group) => {

        const files = Array.from(e.target.files);
        const newImages = files.map((file) => {
            return {
                file,
                previewUrl: URL.createObjectURL(file),
                tempId: generateTempId(),
            };
        });

        setGroups(
            groups.map((g) =>
                g.tempId === group.tempId
                    ? {...g, images: [...g.images, ...newImages]}
                    : g
            )
        );
    };

    const handleDeleteImage = (group, image) => {
        if (image.id) {
            setImagesToDelete([...imagesToDelete, image.id]);
        }
        setGroups(
            groups.map((g) =>
                g.tempId === group.tempId
                    ? {
                        ...g,
                        images: g.images.filter((img) => img !== image),
                    }
                    : g
            )
        );
    };

    const handleSave = async () => {

        try {
            const groupsWithoutSelectedGroup = groups.filter((g) => !g.id);
            if (groupsWithoutSelectedGroup.length > 0) {
                alert('Пожалуйста, выберите группу для всех фоток.');
                return;
            }

            // Удаление групп
            if (groupsToDelete.length > 0) {
                await Promise.all(
                    groupsToDelete.map((groupId) =>
                        deletePhotoGroup(groupId)
                    )
                );
            }

            // Создание новых групп
            const newGroups = groups.filter((g) => !g.id && g.name);
            const createdGroups = await Promise.all(
                newGroups.map(async (group) => {
                    const createdGroup = await createPhotoGroup({
                        name: group.name,
                    });
                    return {...group, id: createdGroup.id};
                })
            );

            // Обновление групп с новыми ID
            const updatedGroups = groups.map((g) => {
                const createdGroup = createdGroups.find(
                    (cg) => cg.tempId === g.tempId
                );
                if (createdGroup) {
                    return {...g, id: createdGroup.id};
                }
                return g;
            });

            // Загрузка новых изображений
            const imagesToUpload = [];
            updatedGroups.forEach((group) => {
                group.images.forEach((image) => {
                    if (image.file) {
                        imagesToUpload.push({
                            file: image.file,
                            groupId: group.id,
                        });
                    }
                });
            });

            if (imagesToUpload.length > 0) {
                await uploadPointImages(feature.properties.id, imagesToUpload);
            }

            // **Оставляем только один вызов deletePointImages**
            if (imagesToDelete.length > 0) {
                await deletePointImages(imagesToDelete, feature.properties.id);
            }

            handleClose();

            alert('Изменения сохранены успешно.');

        } catch (error) {
            console.error('Ошибка при сохранении:', error);
            alert('Произошла ошибка при сохранении.');
        }
    };

    const handleClose = () => {
        setShow(false);
        if (onClose) onClose();
    };

    const handleDragEnter = (e, group) => {
        e.preventDefault();
        e.stopPropagation();
        setGroups(groups.map(g => g.tempId === group.tempId ? {...g, isDragging: true} : g));
    };

    const handleDragOver = (e, group) => {
        e.preventDefault();
        e.stopPropagation();
    };

    const handleDragLeave = (e, group) => {
        e.preventDefault();
        e.stopPropagation();
        setGroups(groups.map(g => g.tempId === group.tempId ? {...g, isDragging: false} : g));
    };

    const handleDrop = (e, group) => {
        e.preventDefault();
        e.stopPropagation();
        setGroups(groups.map(g => g.tempId === group.tempId ? {...g, isDragging: false} : g));
        const files = e.dataTransfer.files;
        handleAddImagesToGroup({target: {files}}, group);
    };

    const handleGroupSelect = (e, group) => {

        const selectedGroupId = parseInt(e.target.value);
        const selectedGroup = availableGroups.find(g => g.id === selectedGroupId);

        setGroups(groups.map((g) => {
            if (g.tempId === group.tempId) {
                return {
                    ...g,
                    id: selectedGroup ? selectedGroup.id : null,
                    name: selectedGroup ? selectedGroup.name : '',
                };
            }
            return g;
        }));
    };

    const getUnusedGroups = (currentGroupId = null) => {
        const usedGroupIds = new Set(
            groups
                .map(g => g.id)
                .filter(id => id != null && id !== currentGroupId)
        );
        return availableGroups.filter(group => !usedGroupIds.has(group.id));
    };

    return (
        <Modal show={show} onHide={handleClose} size="lg">

            {/*<Modal.Header closeButton>*/}
            {/*    <Modal.Title>Фотки по Группам</Modal.Title>*/}
            {/*</Modal.Header>*/}

            <Modal.Header className="d-flex justify-content-between align-items-center">
                <Modal.Title style={{fontSize: '20px'}}>Фотки по Группам</Modal.Title>
                <div className="d-flex align-items-center">
                    <Button variant="secondary" onClick={handleClose} style={{fontSize: '18px', marginRight: '10px'}}>
                        Закрыть
                    </Button>
                    <Button variant="primary" onClick={handleSave} style={{fontSize: '18px', marginRight: 'auto'}}>
                        Сохранить
                    </Button>
                    <CloseButton onClick={handleClose}/>
                </div>
            </Modal.Header>

            <Modal.Body>

                {loading ? (
                    <div className="text-center">Загрузка...</div>
                ) : availableGroups.length === 0 ? (
                    <div className="alert alert-info">
                        Нет групп фоток
                    </div>
                ) : (
                    groups.map((group) => (
                        <div key={group.tempId} className="group-section mb-4">
                            <Row className="align-items-center mb-3">
                                <Col md="auto">
                                    <Form.Label className="mb-0 mr-2"><strong>Название</strong></Form.Label>
                                </Col>
                                <Col md={4}>
                                    <Form.Control
                                        as="select"
                                        value={group.id || ''}
                                        onChange={(e) => handleGroupSelect(e, group)}
                                        style={{maxWidth: '200px', backgroundColor: '#f0f8ff', marginRight: 'auto'}}
                                    >
                                        <option value="">-</option>
                                        {getUnusedGroups(group.id).map((g) => (
                                            <option key={g.id} value={g.id}>
                                                {g.name}
                                            </option>
                                        ))}
                                    </Form.Control>
                                </Col>
                                <Col md="auto" className="ml-auto">
                                    <Button variant="danger" onClick={() => handleDeleteGroup(group)}>
                                        Удалить Фотки группы
                                    </Button>
                                </Col>
                            </Row>
                            <div className="images-section mt-3">

                                <div className="d-flex flex-wrap align-items-center">
                                    <div
                                        className="drop-area mr-2 mb-2"
                                        onDragEnter={(e) => handleDragEnter(e, group)}
                                        onDragOver={(e) => handleDragOver(e, group)}
                                        onDragLeave={(e) => handleDragLeave(e, group)}
                                        onDrop={(e) => handleDrop(e, group)}
                                        style={{
                                            width: '150px',
                                            height: '150px',
                                            border: '2px dashed #ccc',
                                            borderRadius: '5px',
                                            textAlign: 'center',
                                            cursor: 'pointer',
                                            backgroundColor: group.isDragging ? '#f0f8ff' : '#fff',
                                            transition: 'background-color 0.3s',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            flexShrink: 0
                                        }}
                                        onClick={() => document.getElementById(`images-${group.tempId}`).click()}
                                    >
                                        {/*<p>Перетащите сюда изображения или нажмите для выбора</p>*/}
                                        <span style={{fontSize: '48px', color: '#007bff'}}>+</span>
                                        <input
                                            type="file"
                                            id={`images-${group.tempId}`}
                                            name={`images-${group.tempId}`}
                                            multiple
                                            accept="image/*"
                                            style={{display: 'none'}}
                                            onChange={(e) => handleAddImagesToGroup(e, group)}
                                        />
                                    </div>

                                    {group.images.map((image) => (
                                        <div
                                            key={image.tempId || image.id}
                                            className="image-item position-relative mr-2 mb-2"
                                            style={{width: '150px', height: '150px'}}
                                        >
                                            <img
                                                src={image.previewUrl || image.image}
                                                alt="Group Image"
                                                className="img-thumbnail"
                                                style={{width: '100%', height: '100%', objectFit: 'cover'}}
                                            />
                                            <Button
                                                variant="danger"
                                                size="sm"
                                                className="position-absolute"
                                                style={{top: '5px', right: '5px'}}
                                                onClick={() => handleDeleteImage(group, image)}
                                            >
                                                &times;
                                            </Button>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    ))
                )}

                {getUnusedGroups().length > 0 && !loading && (
                    <Button variant="outline-primary" onClick={handleAddGroup}>
                        Добавить в группу
                    </Button>
                )}

            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Закрыть
                </Button>
                <Button variant="primary" onClick={handleSave}>
                    Сохранить
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default ImageGroupsModal;
