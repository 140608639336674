import React, {useEffect, useState} from 'react';
import {Button, Form, Modal} from 'react-bootstrap';
import {createChildPoint, deleteChildPoint, updateChildPoint} from "../../modules/api/polygons";
import {populateCategories} from "../../modules/api/categories";
import {extractDjangoResponseErrorMessages} from "../../modules/api/utils/djangoResponse";

function ChildPointModal({ parentFeature, childFeature, lngLat, onClose, onSuccess }) {

    const [show, setShow] = useState(true);
    const [categories, setCategories] = useState([]);
    const [subcategories, setSubcategories] = useState([]);
    const isEditMode = !!childFeature; // Если есть childFeature, то режим редактирования
    const [errors, setErrors] = useState([]);

    const parseContactInfo = (contactInfo) => {
        if (typeof contactInfo === 'string') {
            try {
                return JSON.parse(contactInfo);
            } catch (error) {
                console.error('Ошибка парсинга contact_info:', error);
                return {};
            }
        }
        return contactInfo || {};
    };

    const contactInfoParsed = isEditMode ? parseContactInfo(childFeature.properties.contact_info) : {};

    const initialFormData = {
        category: isEditMode ? (childFeature.properties.category ?? parentFeature.properties.category ?? '') : (parentFeature.properties.category ?? ''),
        subcategory: isEditMode ? (childFeature.properties.subcategory ?? parentFeature.properties.subcategory ?? '') : (parentFeature.properties.subcategory ?? ''),
        name: isEditMode ? (childFeature.properties.name ?? '') : `Потомок '${parentFeature.properties.name}': (${lngLat.lng}, ${lngLat.lat})`,
        description: isEditMode ? (childFeature.properties.description ?? '') : "",
        short_description: isEditMode ? (childFeature.properties.short_description ?? '') : "",
        address: isEditMode ? (childFeature.properties.address ?? '') : "",
        contactNumber: isEditMode ? (contactInfoParsed.phone_numbers?.[0] ?? '') : "",
        whatsapp: isEditMode ? (contactInfoParsed.whatsapp_urls?.[0] ?? '') : "",
        viber: isEditMode ? (contactInfoParsed.viber_urls?.[0] ?? '') : "",
        telegram: isEditMode ? (contactInfoParsed.telegram_urls?.[0] ?? '') : "",
        line: isEditMode ? (contactInfoParsed.line_urls?.[0] ?? '') : "",
        google_maps_url: isEditMode ? (childFeature.properties.google_maps_url ?? '') : "",
        facebook_urls: isEditMode ? (contactInfoParsed.facebook_urls?.[0] ?? '') : "",
        instagram_urls: isEditMode ? (contactInfoParsed.instagram_urls?.[0] ?? '') : "",
    };

    const [formData, setFormData] = useState(initialFormData);

    useEffect(() => {
        fetchCategories();
    }, []);

    const fetchCategories = async () => {
        try {
            const data = await populateCategories();
            setCategories(data);

            // Устанавливаем подкатегории на основе выбранной категории
            const selectedCategory = data.find(cat => cat.name === formData.category);
            setSubcategories(selectedCategory ? selectedCategory.subcategories : []);
        } catch (error) {
            console.error('Ошибка при получении категорий:', error);
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;

        if (name === 'category') {
            const selectedCat = categories.find(cat => cat.name === value);
            setSubcategories(selectedCat ? selectedCat.subcategories : []);
            setFormData((prevData) => ({
                ...prevData,
                category: value,
                subcategory: '', // Сбрасываем подкатегорию при смене категории
            }));
        } else {
            setFormData((prevData) => ({
                ...prevData,
                [name]: value,
            }));
        }
    };

    const handleSave = async () => {

        formData.contact_info = {
            'phone_numbers': formData.contactNumber ? [formData.contactNumber] : [],
            'whatsapp_urls': formData.whatsapp ? [formData.whatsapp] : [],
            'viber_urls': formData.viber ? [formData.viber] : [],
            'telegram_urls': formData.telegram ? [formData.telegram] : [],
            'line_urls': formData.line ? [formData.line] : [],
            'facebook_urls': formData.facebook_urls ? [formData.facebook_urls] : [],
            'instagram_urls': formData.instagram_urls ? [formData.instagram_urls] : [],
        };

        const data = {
            properties: {
                ...formData,
                parentId: parentFeature.properties.id,
            },
            geometry: {
                type: 'Point',
                coordinates: isEditMode ? childFeature.geometry.coordinates : [lngLat.lng, lngLat.lat],
            },
        };

        try {
            let updatedPoint;
            if (isEditMode) {
                updatedPoint = await updateChildPoint(parentFeature.properties.id, childFeature.properties.id, data);
            } else {
                // Режим создания
                updatedPoint = await createChildPoint(parentFeature.properties.id, data);
            }
            if (onSuccess) onSuccess(updatedPoint, false);

        } catch (error) {

            let errorMessages = [];

            // Проверяем, если ошибка связана с HTTP-ответом
            if (error.response && error.response.data) {
                errorMessages = extractDjangoResponseErrorMessages(error.response.data);
            } else if (error.message) {
                errorMessages = [error.message];
            }

            const errorMsg = `
            Ошибка ${isEditMode ? 'Обновления' : 'Создания'} точки в полигоне: \n${errorMessages.join('\n')}
            `;
            alert(errorMsg);

            setErrors(errorMessages);
        }
    };

    const handleCloseModal = () => {
        setShow(false);
        if (onClose) onClose();
    };

    const handleDelete = async () => {
        if (isEditMode && childFeature) {
            try {
                await deleteChildPoint(parentFeature.properties.id, childFeature.properties.id);
                if (onSuccess) onSuccess(childFeature, true); // Передаем объект точки и флаг удаления
                // handleCloseModal(); // Удаляем этот вызов, чтобы избежать двойного размонтирования
            } catch (error) {
                console.error('Ошибка при удалении точки внутри полигона:', error);
                alert('Произошла ошибка при удалении точки внутри полигона.');
            }
        } else {
            // Если точка еще не создана, просто закрываем модальное окно
            handleCloseModal();
        }
    };

    return (
        <Modal show={show} onHide={handleCloseModal}>

            <Modal.Header closeButton>
                <Modal.Title>{isEditMode ? 'Редактировать точку внутри полигона' : 'Добавить точку внутри полигона'}</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <Form>
                    {/* Категория */}
                    <Form.Group className="mb-3" controlId="category">
                        <Form.Label>Категория</Form.Label>
                        <Form.Control
                            as="select"
                            name="category"
                            value={formData.category}
                            onChange={handleChange}
                            required
                        >
                            <option value="">Выберите категорию</option>
                            {categories.map((category) => (
                                <option key={category.id} value={category.name}>
                                    {category.name}
                                </option>
                            ))}
                        </Form.Control>
                    </Form.Group>

                    {/* Подкатегория */}
                    <Form.Group className="mb-3" controlId="subcategory">
                        <Form.Label>Подкатегория</Form.Label>
                        <Form.Control
                            as="select"
                            name="subcategory"
                            value={formData.subcategory}
                            onChange={handleChange}
                            required
                        >
                            <option value="">Выберите подкатегорию</option>
                            {subcategories.map((subcategory) => (
                                <option key={subcategory.id} value={subcategory.name}>
                                    {subcategory.name}
                                </option>
                            ))}
                        </Form.Control>
                    </Form.Group>

                    {/* Название точки */}
                    <Form.Group className="mb-3" controlId="pointName">
                        <Form.Label>Название точки</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Введите название точки"
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                            required
                        />
                    </Form.Group>

                    {/* Описание */}
                    <Form.Group className="mb-3" controlId="pointDescription">
                        <Form.Label>Описание</Form.Label>
                        <Form.Control
                            as="textarea"
                            rows={3}
                            placeholder="Введите описание"
                            name="description"
                            value={formData.description}
                            onChange={handleChange}
                        />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="short_description">
                        <Form.Label>Краткое описание</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Введите краткое описание"
                            name="short_description"
                            value={formData.short_description}
                            onChange={handleChange}
                        />
                    </Form.Group>

                    {/* Адрес */}
                    <Form.Group className="mb-3" controlId="address">
                        <Form.Label>Адрес</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Введите адрес"
                            name="address"
                            value={formData.address}
                            onChange={handleChange}
                        />
                    </Form.Group>

                    {/* Контактный номер */}
                    <Form.Group className="mb-3" controlId="contactNumber">
                        <Form.Label>Контактный номер</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Введите контактный номер"
                            name="contactNumber"
                            value={formData.contactNumber}
                            onChange={handleChange}
                        />
                    </Form.Group>

                    {/* WhatsApp */}
                    <Form.Group className="mb-3" controlId="whatsapp">
                        <Form.Label>WhatsApp</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Введите WhatsApp"
                            name="whatsapp"
                            value={formData.whatsapp}
                            onChange={handleChange}
                        />
                    </Form.Group>

                    {/* Viber */}
                    <Form.Group className="mb-3" controlId="viber">
                        <Form.Label>Viber</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Введите Viber"
                            name="viber"
                            value={formData.viber}
                            onChange={handleChange}
                        />
                    </Form.Group>

                    {/* Telegram */}
                    <Form.Group className="mb-3" controlId="telegram">
                        <Form.Label>Telegram</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Введите Telegram"
                            name="telegram"
                            value={formData.telegram}
                            onChange={handleChange}
                        />
                    </Form.Group>

                    {/* Line */}
                    <Form.Group className="mb-3" controlId="line">
                        <Form.Label>Line</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Введите Line"
                            name="line"
                            value={formData.line}
                            onChange={handleChange}
                        />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="google_maps_url">
                        <Form.Label>Ссылка на Google Maps</Form.Label>
                        <Form.Control
                            type="url"
                            placeholder="Введите ссылку на Google Maps"
                            name="google_maps_url"
                            value={formData.google_maps_url}
                            onChange={handleChange}
                        />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="facebook_urls">
                        <Form.Label>Facebook</Form.Label>
                        <Form.Control
                            type="url"
                            placeholder="Ссыль на Facebook"
                            name="facebook_urls"
                            value={formData.facebook_urls}
                            onChange={handleChange}
                        />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="instagram_urls">
                        <Form.Label>Instagram</Form.Label>
                        <Form.Control
                            type="url"
                            placeholder="Ссыль на Instagram"
                            name="instagram_urls"
                            value={formData.instagram_urls}
                            onChange={handleChange}
                        />
                    </Form.Group>

                </Form>
            </Modal.Body>

            {errors.length > 0 && (
                <div className="alert alert-danger">
                    <ul>
                        {errors.map((msg, index) => (
                            <li key={index}>{msg}</li>
                        ))}
                    </ul>
                </div>
            )}

            <Modal.Footer>
                {isEditMode && (
                    <Button variant="danger" onClick={handleDelete}>
                        Удалить
                    </Button>
                )}
                <Button variant="primary" onClick={handleSave}>
                    Сохранить
                </Button>
            </Modal.Footer>
        </Modal>
    );

}

export default ChildPointModal;
