// TODO нихуя не получается все равно ошибки джанги достать отсюда
export const extractDjangoResponseErrorMessages = (errorObj) => {
    let messages = [];

    const traverse = (obj) => {
        if (typeof obj === 'string') {
            messages.push(obj);
        } else if (Array.isArray(obj)) {
            obj.forEach(item => traverse(item));
        } else if (typeof obj === 'object' && obj !== null) {
            Object.values(obj).forEach(value => traverse(value));
        }
    };

    traverse(errorObj);
    return messages;
};
