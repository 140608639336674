import { API_ADMIN_TOKEN, API_URL } from './config';


export async function fetchReviewsForPoint(pointId) {
    const url = `${API_URL}/api/points/${pointId}/reviews/`;

    try {
        const response = await apiRequest(url, {
            method: 'GET',
        });

        if (!response.ok) {
            throw new Error(`Ошибка сети: ${response.status} ${response.statusText}`);
        }

        return await response.json(); // Возвращаем массив отзывов
    } catch (error) {
        console.error('Ошибка при загрузке отзывов:', error);
        return []; // Возвращаем пустой массив в случае ошибки
    }
}

export async function createReview(pointId, reviewData) {
    const url = `${API_URL}/api/points/${pointId}/reviews/`;

    try {
        const response = await apiRequest(url, {
            method: 'POST',
            body: JSON.stringify(reviewData),
        });

        if (!response.ok) {
            throw new Error(`Ошибка сети: ${response.status} ${response.statusText}`);
        }

        return await response.json(); // Возвращаем созданный отзыв
    } catch (error) {
        console.error('Ошибка при создании отзыва:', error);
        throw error;
    }
}


export async function updateReview(pointId, reviewId, reviewData) {
    const url = `${API_URL}/api/points/${pointId}/reviews/${reviewId}/`;


    try {
        const response = await apiRequest(url, {
            method: 'PATCH',
            body: JSON.stringify(reviewData),
        });

        if (!response.ok) {
            throw new Error(`Ошибка сети: ${response.status} ${response.statusText}`);
        }

        return await response.json(); // Возвращаем обновленный отзыв
    } catch (error) {
        console.error('Ошибка при обновлении отзыва:', error);
        throw error;
    }
}

export async function deleteReview(pointId, reviewId) {
    const url = `${API_URL}/api/points/${pointId}/reviews/${reviewId}/`;

    try {
        const response = await apiRequest(url, {
            method: 'DELETE',
        });

        if (!response.ok) {
            throw new Error(`Ошибка сети: ${response.status} ${response.statusText}`);
        }

        console.log(`Отзыв с ID ${reviewId} удален`);
    } catch (error) {
        console.error(`Ошибка удаления отзыва ${reviewId}:`, error);
        throw error;
    }
}

export async function displayReviews(pointId) {
    const reviewsListElement = document.getElementById('reviewsList');
    reviewsListElement.innerHTML = ''; // Очищаем предыдущий список отзывов

    // Получаем отзывы с сервера
    const reviews = await fetchReviewsForPoint(pointId);

    if (reviews.length === 0) {
        reviewsListElement.innerHTML = '<p>Отзывов пока нет.</p>';
        return;
    }

    // Создаем HTML для каждого отзыва
    reviews.forEach(review => {
        console.log(review)
        const reviewElement = document.createElement('div');
        reviewElement.classList.add('review-item');

        const reviewDate = new Date(review.created_at);
        const formattedDate = reviewDate.toLocaleDateString('ru-RU', {
            day: 'numeric',
            month: 'short',
            year: 'numeric'
        });

        // Добавляем содержимое отзыва и кнопки редактирования и удаления
        reviewElement.innerHTML = `
            <p><strong>Пользователь:</strong> ${review.username}</p>
            <p><strong>Рейтинг:</strong> ${'⭐'.repeat(review.rating)}</p>
            <p><strong>Комментарий:</strong> ${review.comment}</p>
            <p><small>Дата: ${formattedDate}</small></p>
            <button class="btn btn-secondary btn-sm edit-review" data-review-id="${review.id}">Редактировать</button>
            <button class="btn btn-danger btn-sm delete-review" data-review-id="${review.id}">Удалить</button>
        `;

        reviewsListElement.appendChild(reviewElement);
    });

    // Добавляем обработчики для кнопок "Удалить" и "Редактировать"
    document.querySelectorAll('.delete-review').forEach(button => {
        button.addEventListener('click', async (event) => {
            const reviewId = event.target.getAttribute('data-review-id');
            try {
                await deleteReview(pointId, reviewId);
                await displayReviews(pointId); // Обновляем список отзывов после удаления
            } catch (error) {
                alert('Ошибка при удалении отзыва.');
                console.error(error);
            }
        });
    });

    document.querySelectorAll('.edit-review').forEach(button => {
        button.addEventListener('click', (event) => {
            const reviewId = event.target.getAttribute('data-review-id');
            const review = reviews.find(r => r.id == reviewId);
            if (review) {
                // Заполняем форму редактирования текущими данными отзыва
                document.getElementById('review-rating').value = review.rating;
                document.getElementById('review-comment').value = review.comment;
                document.getElementById('save-review').setAttribute('data-review-id', review.id);
            }
        });
    });
}
