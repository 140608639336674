import {API_ADMIN_TOKEN, API_URL} from './config';


const TAGS_URL_SUFFIX = '/api/tags/'
const TAGS_URL = `${API_URL}${TAGS_URL_SUFFIX}`
export const TAGS_UPDATE_INTERVAL = 5 * 60 * 1000;


// Функция для загрузки тегов событий
// export async function populateTags() {
//     try {
//         const response = await fetch(TAGS_URL);
//         const tags = await response.json();
//
//         const tagsSelect = document.getElementById('tags');
//
//         // TODO тестим без дефолтного тэга
//         // tagsSelect.innerHTML = '<option value="">Выберите тег</option>';
//         // Очищаем select перед добавлением новых опций
//         tagsSelect.innerHTML = '';
//
//         // Добавляем каждый тег в виде опции
//         tags.forEach(tag => {
//             const option = document.createElement('option');
//             option.value = tag.id;
//             option.textContent = tag.name;
//             tagsSelect.appendChild(option);
//         });
//     } catch (error) {
//         console.error('Ошибка загрузки тегов событий:', error);
//     }
// }

export const getTags = async () => {
    const storedTags = JSON.parse(localStorage.getItem('tags'));

    if (storedTags && (Date.now() - storedTags.timestamp) < TAGS_UPDATE_INTERVAL) {
        populateTags(storedTags.data); // Отображаем теги из localStorage, если они актуальны
        return storedTags.data;
    }
    return await fetchTags(); // Иначе делаем запрос на сервер
};

const fetchTags = async () => {
    try {
        const response = await apiRequest(TAGS_URL);
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const tags = await response.json();

        // Сохраняем теги и время последнего обновления в localStorage
        localStorage.setItem('tags', JSON.stringify({
            data: tags,
            timestamp: Date.now()
        }));

        // Обновляем отображение тегов в HTML после успешной загрузки
        populateTags(tags);
        return tags;
    } catch (error) {
        console.error('Ошибка загрузки тегов:', error);
        return null;
    }
};


export const startFetchingTags = (interval) => {
    // Первоначальная загрузка тегов
    getTags();

    // Периодическое обновление тегов
    const intervalId = setInterval(fetchTags, interval);

    // Возвращаем идентификатор интервала
    return intervalId;
};


export const populateTags = (tags) => {

    const tagsSelect = document.getElementById('tags');
    if (!tagsSelect) {
        console.warn('Элемент с ID "tags" не найден');
        return;
    }

    // Очищаем select перед добавлением новых опций
    tagsSelect.innerHTML = '';

    // Добавляем каждый тег в виде опции
    tags.forEach(tag => {
        const option = document.createElement('option');
        option.value = tag.id;
        option.textContent = tag.name;
        tagsSelect.appendChild(option);
    });
};

export async function createTag(tagName) {
    try {
        const response = await apiRequest(`${API_URL}/api/tags/`, {
            method: 'POST',
            body: JSON.stringify({ name: tagName }),
        });

        if (!response.ok) {
            throw new Error(`Не удалось создать тег: ${response.statusText}`);
        }

        const newTag = await response.json(); // Получаем созданный тег

        await fetchTags();

        return newTag;
    } catch (error) {
        console.error('Ошибка при добавлении тега:', error);
        throw error;
    }
}
