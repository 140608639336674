import React from 'react';
import {createRoot} from 'react-dom/client';
import ChildPointModal from "../../components/polygons/AddChildPointModal";
import {map} from "../../index";

let root = null;

export function renderChildPointModal(parentFeature, childFeature = null, lngLat = null, onClose) {
    // Получаем контейнер для модального окна
    const modalRoot = document.getElementById('modal-root');
    if (!modalRoot) {
        console.error('Модалка react для Полигонов не найдена');
        return;
    }

    if (!root) {
        root = createRoot(modalRoot);
    }

    root.render(
        <ChildPointModal
            parentFeature={parentFeature}
            childFeature={childFeature}
            lngLat={lngLat}
            onClose={() => {
                // Очистка после закрытия модального окна
                root.unmount();
                root = null;
                if (onClose) onClose();
            }}
            onSuccess={(updatedPoint, isDeleted) => {
                if (isDeleted) {
                    removeChildPointFromMap(updatedPoint);
                } else {
                    addOrUpdateChildPointOnMap(updatedPoint);
                }

                root.unmount();
                root = null;
            }}
        />
    );
}

function addOrUpdateChildPointOnMap(childPoint) {
    console.log('addOrUpdateChildPointOnMap вызывается с:', childPoint);

    const source = map.getSource('polygon-children');
    if (!source) {
        console.error('Источник "polygon-children" не найден на карте');
        return;
    }

    // Получаем текущие данные источника
    const currentData = source._data;
    if (!currentData || !currentData.features) {
        console.error('Данные источника "polygon-children" некорректны:', currentData);
        return;
    }

    // Находим индекс дочерней точки по id
    const featureIndex = currentData.features.findIndex(
        (f) => f.properties.id === childPoint.properties.id
    );

    if (featureIndex !== -1) {
        // Обновляем существующую дочернюю точку
        currentData.features[featureIndex] = childPoint;
        console.log(`Обновляем существующую дочернюю точку с ID ${childPoint.properties.id}`);
    } else {
        // Добавляем новую дочернюю точку
        currentData.features.push(childPoint);
        console.log(`Добавляем новую дочернюю точку с ID ${childPoint.properties.id}`);
    }
    source.setData(currentData);
    console.log('Источник данных "polygon-children" обновлён');
}

function removeChildPointFromMap(childPoint) {
    console.log('removeChildPointFromMap вызывается с:', childPoint);

    // Получаем parentId из дочерней точки
    const parentId = childPoint.properties.parentId;
    if (!parentId) {ensurePolygonChildrenSource
        console.error('Дочерняя точка не имеет parentId:', childPoint);
        return;
    }

    // Получаем текущие дочерние точки для этого полигона
    const existingChildPoints = map.getSource('polygon-children')._data.features.filter(f => f.properties.parentId === parentId);

    // Фильтруем, удаляя нужную точку
    const updatedChildPoints = existingChildPoints.filter(f => f.properties.id !== childPoint.properties.id);

    // Создаём новый GeoJSON для 'polygon-children'
    const updatedChildrenGeoJSON = {
        type: 'FeatureCollection',
        features: map.getSource('polygon-children')._data.features
            .filter(f => f.properties.parentId !== parentId) // Убираем старые точки этого полигона
            .concat(updatedChildPoints) // Добавляем обновлённые точки
    };

    // Обновляем источник данных 'polygon-children'
    if (map.getSource('polygon-children')) {
        map.getSource('polygon-children').setData(updatedChildrenGeoJSON);
        console.log('Источник данных "polygon-children" обновлён после удаления');
    } else {
        console.error('Источник "polygon-children" не найден на карте');
    }
}
