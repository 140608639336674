import {API_ADMIN_TOKEN, API_URL} from './config';



export async function createChildPoint(parentPointId, data) {

    console.log(data);

    const response = await apiRequest(`${API_URL}/api/points/${parentPointId}/add_child_point/`, {
        method: 'POST',
        body: JSON.stringify(data),
    });

    if (!response.ok) {
        const errorData = await response.json();
        throw new Error(JSON.stringify(errorData));
    }

    return response.json();
}


export async function updateChildPoint(parentId, childId, data) {
    // TODO сделать редактирование точки на полигон
    const url = `${API_URL}/api/points/${parentId}/children/${childId}/update/`;

    try {
        const response = await apiRequest(url, {
            method: 'PUT', // или 'PATCH', в зависимости от вашего API
            body: JSON.stringify(data),
        });

        if (!response.ok) {
            throw new Error('Ошибка сети: ' + response.statusText);
        }

        return await response.json();
    } catch (error) {
        console.error('Ошибка при обновлении дочерней точки:', error);
        throw error;
    }
}


export async function deleteChildPoint(parentPointId, childPointId) {
    const response = await fetch(`${API_URL}/api/points/${parentPointId}/delete_child_point/`, {
        method: 'DELETE',
        headers: {
            'Authorization': `Token ${API_ADMIN_TOKEN}`,
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({child_point_id: childPointId}),
    });

    if (!response.ok) {
        const errorData = await response.json();
        throw new Error(JSON.stringify(errorData));
    }
}

