// styles.js
import { fetchMapStyles, fetchUserMapStyle, updateUserMapStyle } from "./modules/api/styleMapUser";
import {map} from "./index";

export const displayStyles = async () => {
    const styleList = document.getElementById('style-list');
    styleList.innerHTML = '';

    try {
        const styles = await fetchMapStyles();
        const userData = await fetchUserMapStyle();
        const selectedStyleId = userData.selected_map_style?.id; // Получаем выбранный стиль пользователя

        styles.forEach(style => {
            const listItem = document.createElement('li');
            listItem.textContent = style.name;

            const button = document.createElement('button');
            button.textContent = 'Выбрать';
            button.onclick = async () => {
                if (style.id === selectedStyleId) {
                    alert('Выбранный стиль уже установлен');
                    return;
                }

                // Обновляем выбранный стиль пользователя
                const updatedUserData = await updateUserMapStyle(style.id);
                const styleUrl = updatedUserData.selected_map_style?.style_url;
                const fontName = updatedUserData.selected_map_style?.font_name;

                updateMapStyle(styleUrl); // Обновляем стиль карты
                if (styleUrl) {
                    localStorage.setItem('selectedMapStyle', styleUrl);
                }
                if (fontName) {
                    localStorage.setItem('selectedFontName', fontName);
                }
            };

            if (style.id === selectedStyleId) {
                listItem.style.fontWeight = 'bold';
            }

            listItem.appendChild(button);
            styleList.appendChild(listItem);
        });
    } catch (error) {
        console.error('Error fetching styles:', error);
    }
};

// Функция для обновления стиля карты
const updateMapStyle = (newStyleId) => {
    map.setStyle(newStyleId);
};
