import { loginUser } from "./api/userProfile"; // Удалили импорт registerUser

export function initializeAuthAndProfileModals() {
    // Получение элементов модальных окон и кнопок
    const authModalElement = document.getElementById('auth-modal');
    const profileModalElement = document.getElementById('profile-modal');
    const profileBtn = document.getElementById('profile-btn');
    const loginForm = document.getElementById('login-form');
    const logoutBtn = document.getElementById('logout-btn');

    // Инициализация Bootstrap модальных окон
    const authModal = new bootstrap.Modal(authModalElement, {
        backdrop: 'static',
        keyboard: false
    });
    const profileModal = new bootstrap.Modal(profileModalElement);

    // Функция для открытия модального окна
    function openModal(modal) {
        modal.show();
    }

    // Функция для закрытия модального окна
    function closeModal(modal) {
        modal.hide();
    }

    // Функция для обновления интерфейса на основе состояния аутентификации
    function updateUI() {
        const token = localStorage.getItem('authToken');
        const username = localStorage.getItem('username');

        if (token) {
            // Пользователь аутентифицирован
            document.getElementById('user-info').innerText = `Вы вошли как: ${username}`;
            // Здесь вы можете включить/отключить элементы интерфейса, требующие авторизации
            enableEditingFeatures();
        } else {
            // Пользователь не аутентифицирован
            document.getElementById('user-info').innerText = '';
            // Здесь вы можете отключить элементы интерфейса, требующие авторизации
            disableEditingFeatures();
        }
    }

    // Функции для управления доступностью функциональности редактирования
    function enableEditingFeatures() {
        // Пример: включение кнопки редактирования
        const editButtons = document.querySelectorAll('.edit-button');
        editButtons.forEach(button => {
            button.disabled = false;
            button.classList.remove('disabled');
        });
        // Добавьте здесь любую другую логику для включения функциональности редактирования
    }

    function disableEditingFeatures() {
        // Пример: отключение кнопки редактирования
        const editButtons = document.querySelectorAll('.edit-button');
        editButtons.forEach(button => {
            button.disabled = true;
            button.classList.add('disabled');
        });
        // Добавьте здесь любую другую логику для отключения функциональности редактирования
    }

    // Обработчик для кнопки профиля
    profileBtn.addEventListener('click', () => {
        const token = localStorage.getItem('authToken');
        const username = localStorage.getItem('username');

        if (token) {
            // Пользователь аутентифицирован, показать профиль
            document.getElementById('user-info').innerText = `Вы вошли как: ${username}`;
            openModal(profileModal);
        } else {
            // Пользователь не аутентифицирован, показать модальное окно аутентификации
            openModal(authModal);
        }
    });

    // Обработчик отправки формы входа
    loginForm.addEventListener('submit', async (event) => {
        event.preventDefault();
        const email = document.getElementById('login-email').value;
        const password = document.getElementById('login-password').value;

        const result = await loginUser(email, password);
        if (result.success) {
            const data = result.data;
            const token = data.auth_token;

            // Сохраняем токен и имя пользователя в localStorage
            localStorage.setItem('authToken', token);
            localStorage.setItem('username', email); // Сохраняем email как имя пользователя

            // Обновляем интерфейс
            updateUI();

            // Закрываем модальное окно аутентификации
            closeModal(authModal);

            // Показываем профиль
            openModal(profileModal);

            alert('Вы успешно вошли в систему!');
        } else {
            const errors = result.errors;
            const errorMessages = Object.values(errors).flat().join('\n');
            alert(`Ошибка авторизации:\n${errorMessages}`);
        }
    });

    // Обработчик для кнопки выхода
    logoutBtn.addEventListener('click', () => {
        // Удаляем токен и имя пользователя из localStorage
        localStorage.removeItem('authToken');
        localStorage.removeItem('username');

        // Обновляем интерфейс
        updateUI();

        // Закрываем модальное окно профиля
        closeModal(profileModal);

        alert('Вы вышли из системы.');

        // Показать модальное окно авторизации после выхода
        openModal(authModal);
    });

    // Автоматическая проверка аутентификации при загрузке страницы
    window.addEventListener('load', () => {
        const token = localStorage.getItem('authToken');
        const username = localStorage.getItem('username');

        if (!token) {
            // Пользователь не аутентифицирован, открыть модальное окно аутентификации
            openModal(authModal);
        } else {
            // Пользователь аутентифицирован, обновить интерфейс
            updateUI();
        }
    });
}

