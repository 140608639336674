import {API_URL} from "./config";


/**
 * Получение всех групп фотографий
 * @returns {Promise<Array>} - Список групп
 */
export const fetchPhotoGroups = async () => {
    const response = await apiRequest(`${API_URL}/api/photo_groups/`, {
        method: "GET",
    });
    if (!response.ok) {
        throw new Error('Ошибка при получении групп фотографий');
    }
    return response.json();
};

/**
 * Создание новой группы фотографий
 * @param {Object} data - Данные для создания группы
 * @returns {Promise<Object>} - Созданная группа
 */
export const createPhotoGroup = async (data) => {

    const response = await apiRequest(`${API_URL}/api/photo_groups/`, {
        method: 'POST',
        body: JSON.stringify(data),
    });

    if (!response.ok) {
        throw new Error(`Не удалось создать группу фотографий: ${response.statusText}`);
    }

    return await response.json();
};

/**
 * Удаление группы фотографий по ID
 * @param {number} groupId - ID группы для удаления
 * @returns {Promise<void>}
 */
export const deletePhotoGroup = async (groupId) => {

    const response = await apiRequest(`${API_URL}/api/photo_groups/${groupId}/`, {
        method: 'DELETE',
    });

    if (!response.ok) {
        throw new Error(`Не удалось удалить группу фотографий: ${response.statusText}`);
    }

    console.log(`Группа фотографий с ID ${groupId} успешно удалена.`);
};
