import {API_URL} from "./config";


export async function getMarkerTypes() {
    try {
        const response = await apiRequest(`${API_URL}/api/marker_types/`);
        if (!response.ok) {
            throw new Error(`Network response was not ok: ${response.status}`);
        }
        const data = await response.json();
        // Ожидается, что data — это массив объектов { id, name, logo_url }
        return data;
    } catch (error) {
        console.error('Ошибка при загрузке иконок:', error);
        return [];
    }
}
