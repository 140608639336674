import {fetchAllPoints} from './api/points';

export const localStoragePlacesDataName = 'placesData';

export const MARKER_TYPE_ZOOM_DEFAULT = 1.5;

// загрузка данных из localStorage
export async function loadPlacesData() {

    try {
        return await fetchAllPoints();
    } catch (error) {
        console.error('Ошибка при загрузке данных с сервера:', error);
        return {
            type: 'FeatureCollection',
            features: [],
        };
    }
}

// создание данных в localStorage
export function savePlacesData(data) {
    localStorage.setItem(localStoragePlacesDataName, JSON.stringify(data));
}

export async function getPlacesData() {
    const data = localStorage.getItem(localStoragePlacesDataName);
    if (data) {
        return JSON.parse(data); // Возвращаем данные из localStorage
    } else {
        const response = getPlacesData()
        const fetchedData = await response.json();
        savePlacesData(fetchedData);
        return fetchedData;
    }
}

// Пример данных по умолчанию, если localStorage пуст
export function getDefaultPlacesData() {
    return {
        'type': 'FeatureCollection',
        'features': [
            {
                'type': 'Feature',
                'properties': {
                    'id': 1,
                    'description': '<strong>Первый</strong><p>Первая опись</p>',
                    'marker-type': 'custom-marker',
                    'image': 'https://i.imgur.com/BMzCtBO.png',
                },
                'geometry': {
                    'type': 'Point',
                    'coordinates': [98.3923, 7.8804]
                }
            },
            {
                'type': 'Feature',
                'properties': {
                    'id': 2,
                    'description': '<strong>Второй</strong><p>Вторая опись</p>',
                    'marker-type': 'second-marker',
                    'image': 'https://i.imgur.com/BMzCtBO.png',
                },
                'geometry': {
                    'type': 'Point',
                    'coordinates': [98.398530, 7.890309]
                }
            }
        ]
    };
}

// Функция для добавления новой точки на карту
export function addNewPlaceSingle(data, newPlace) {
    data.features.push(newPlace);
    // TODO пока не сохраняем
    // savePlacesData(data);
    return data;
}

export function addNewPlaces(data, newPlaces) {
    if (Array.isArray(newPlaces.features)) {
        data.features = data.features.concat(newPlaces.features);  // Добавляем все фичи
        savePlacesData(data);  // Сохраняем обновленные данные в localStorage
    } else {
        console.error('Ошибка: newPlaces не является массивом фич');
    }
    return data;
}
