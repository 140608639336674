import {API_ADMIN_TOKEN, API_URL} from './config';
import {clearPolygon} from "./points";


const EVENTS_URL_SUFFIX = '/api/events/'
const EVENT_TYPES_URL_SUFFIX = '/api/events/types/'

const EVENT_TYPES_URL = `${API_URL}${EVENT_TYPES_URL_SUFFIX}`


export async function fetchAllEvents() {
    const url = `${API_URL}${EVENTS_URL_SUFFIX}`; // URL для загрузки событий

    try {
        const response = await apiRequest(url, {
            method: 'GET',
        });

        if (!response.ok) {
            throw new Error(`Ошибка сети: ${response.status} ${response.statusText}`);
        }

        const events = await response.json(); // Массив событий

        return {
            type: 'FeatureCollection',
            features: events, // Возвращаем события
        };
    } catch (error) {
        console.error('Ошибка при загрузке событий:', error);
        return {
            type: 'FeatureCollection',
            features: [],
        };
    }
}


// Функция для загрузки конкретного события
export async function populateEvent(eventId) {
    try {
        const response = await apiRequest(`${API_URL}${EVENTS_URL_SUFFIX}${eventId}/`);

        if (!response.ok) {
            throw new Error('Network response was not ok ' + response.statusText);
        }

        const event = await response.json();

        // Return the event data
        return event; // Return the fetched event data

    } catch (error) {
        console.error('Ошибка загрузки события:', error);
    }
}

export async function updateEvent(id, eventData) {
    const url = `${API_URL}/api/events/${id}/`;

    const eventPayload = {
        properties: {
            name: eventData.name,
            address: eventData.address,
            description: eventData.description,
            short_description: eventData.shortDescription,
            marker_type: eventData.markerType,
            start_dt: eventData.startDt,
            end_dt: eventData.endDt,
            type: eventData.eventType,
            notification_enabled: eventData.notificationEnabled,
            contact_info: {
                phone_numbers: eventData.contactInfo.phoneNumbers || [],
                whatsapp_urls: eventData.contactInfo.whatsappUrls || [],
                viber_urls: eventData.contactInfo.viberUrls || [],
                telegram_urls: eventData.contactInfo.telegramUrls || [],
                instagram_urls: eventData.contactInfo.instagramUrls || [],
                line_urls: eventData.contactInfo.lineUrls || [],
                websites: eventData.contactInfo.websites || [],
            },
            tags: eventData.tags,
            geometry: {
                type: "Point",
                coordinates: eventData.coordinates,
            }, // Включаем геометрию здесь
        }
    };

    try {
        // Шаг 1: Обновляем данные события
        const response = await apiRequest(url, {
            method: 'PATCH',
            body: JSON.stringify(eventPayload),
        });

        if (!response.ok) {
            throw new Error('Ошибка сети: ' + response.statusText);
        }

        const responseData = await response.json();
        const eventId = responseData.properties.id;

        if (eventData.logo) {
            const logoFormData = new FormData();
            logoFormData.append('logo', eventData.logo);

            const logoResponse = await apiRequest(`${API_URL}/api/events/${eventId}/logo/`, {
                method: 'PATCH',
                body: logoFormData,
            });

            if (!logoResponse.ok) {
                throw new Error('Ошибка при загрузке логотипа: ' + logoResponse.statusText);
            }

            const logoResponseData = await logoResponse.json();
            console.log('Логотип успешно загружен:', logoResponseData);
        }

        if (eventData.images.length > 0) {
            const imagesFormData = new FormData();
            eventData.images.forEach((image) => {
                imagesFormData.append('images', image); // добавляем файл изображения
            });

            const imagesResponse = await apiRequest(`${API_URL}/api/events/${eventId}/images/`, {
                method: 'POST',
                body: imagesFormData,
            });

            if (!imagesResponse.ok) {
                throw new Error('Ошибка при загрузке изображений: ' + imagesResponse.statusText);
            }

            const imagesResponseData = await imagesResponse.json();
            console.log('Изображения успешно загружены:', imagesResponseData);
        }

        return responseData; // Возвращаем обновленное событие

    } catch (error) {
        console.error('Ошибка при обновлении события:', error);
        throw error;
    }
}



// Функция для загрузки типов событий
export async function populateEventTypes() {
    try {
        const response = await apiRequest(EVENT_TYPES_URL);
        const eventTypes = await response.json();

        const eventTypeSelect = document.getElementById('event-type');

        // Очищаем select перед добавлением новых опций
        eventTypeSelect.innerHTML = '<option value="">Выберите тип события</option>';

        // Добавляем каждый тип события в виде опции
        eventTypes.forEach(eventType => {
            const option = document.createElement('option');
            option.value = eventType.id;
            option.textContent = eventType.name;
            eventTypeSelect.appendChild(option);
        });
    } catch (error) {
        console.error('Ошибка загрузки типов событий:', error);
    }
}

// Функция для создания Event'а
export async function createEvent(eventData) {

    const url = `${API_URL}/api/events/`;

    // Создаем payload для JSON-данных
    const payload = {
        type: "Feature",
        properties: {
            name: eventData.name,
            description: eventData.description,
            short_description: eventData.shortDescription,
            marker_type: eventData.markerType,
            type: eventData.eventType,
            start_dt: eventData.startDt,
            end_dt: eventData.endDt,
            notification_enabled: eventData.notificationEnabled,
            tags: eventData.tags,
            contact_info: {
                phone_numbers: eventData.contactInfo.phoneNumbers || [],
                whatsapp_urls: eventData.contactInfo.whatsappUrls || [],
                viber_urls: eventData.contactInfo.viberUrls || [],
                telegram_urls: eventData.contactInfo.telegramUrls || [],
                line_urls: eventData.contactInfo.lineUrls || [],
                websites: eventData.contactInfo.websites || [],
            }
        },
        geometry: {
            type: "Point",
            coordinates: eventData.coordinates
        }
    };

    try {
        // Отправляем JSON-данные
        const response = await apiRequest(url, {
            method: 'POST',
            body: JSON.stringify(payload)
        });

        if (!response.ok) {
            throw new Error('Ошибка сети: ' + response.statusText);
        }

        const responseData = await response.json();
        console.log('Событие успешно создано:', responseData);

        // Получаем ID созданного события
        const eventId = responseData.properties.id;

        // После успешного создания события, отправляем файлы
        if (eventData.images.length > 0) {
            const formData = new FormData();

            // if (eventData.logo) {
            //     formData.append('logo', eventData.logo);
            // }

            if (eventData.images.length > 0) {
                eventData.images.forEach(image => {
                    formData.append('images', image); // здесь добавляем просто сам файл
                });
            }

            // Отправляем файлы
            const fileResponse = await apiRequest(`${url}${eventId}/images/`, {
                method: 'POST', // Используем PATCH для обновления
                body: formData
            });

            if (!fileResponse.ok) {
                throw new Error('Ошибка при загрузке файлов: ' + fileResponse.statusText);
            }

            const fileResponseData = await fileResponse.json();
            console.log('Файлы успешно загружены:', fileResponseData);
        }

    } catch (error) {
        console.error('Ошибка при создании события:', error);
    }
}

export async function deleteEvent(id) {

    const url = `${API_URL}/api/events/${id}/`;

    try {
        const response = await apiRequest(url, {
            method: 'DELETE',
        });

        if (!response.ok) {
            throw new Error('Ошибка сети: ' + response.statusText);
        }
        console.log(`Событие с ID ${id} удалена`);
    } catch (error) {
        console.error(`Ошибка удаления События ${id}:`, error);
        throw error;
    }
}

export async function createEventType(eventName) {
    try {
        const response = await apiRequest(`${API_URL}/api/events/types/`, {
            method: 'POST',
            body: JSON.stringify({ name: eventName }),
        });

        if (!response.ok) {
            throw new Error(`Не удалось создать тег: ${response.statusText}`);
        }

        const newEventType = await response.json(); // Получаем созданный тег

        await populateEventTypes();

        return newEventType;
    } catch (error) {
        console.error('Ошибка при добавлении тега:', error);
        throw error;
    }
}


